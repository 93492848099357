<template>
  <div class="h-100">
    <navbar-component/>
    <!--    Home page-->
    <div class="container my-5 pt-5 pb-5">
      <div class="text-center">
        <h2>
          We do love to hear from you.
        </h2>
      </div>
      <div class="mt-5">
        <a-row :justify="'center'" :gutter="30">
          <a-col :span="12">
            <img src="@/assets/images/undraw_contact_us_re_4qqt.svg" alt="contact-us" class="w-100">
          </a-col>
          <a-col :span="12">
            <a-form
                :model="formState"
                name="basic"
                autocomplete="off"
                @finish="onFinish"
                @finishFailed="onFinishFailed"
                layout="vertical"
            >
              <a-form-item
                  label="Full Name"
                  name="name"
                  :rules="[{ required: true, message: 'Please input your full name!' }]"
              >
                <a-input v-model:value="formState.name" />
              </a-form-item>

              <a-form-item
                  label="Email"
                  name="email"
                  :rules="[{ required: true, message: 'Please input your email!' }]"
              >
                <a-input v-model:value="formState.email" />
              </a-form-item>

              <a-form-item
                  label="Message"
                  name="message"
                  :rules="[{ required: true, message: 'Please input your message!' }]"
              >
                <a-textarea v-model:value="formState.message" :autosize="{minRows: 6, maxRows: 6}" />
              </a-form-item>

              <a-form-item class="text-center">
                <a-button type="primary" html-type="submit" :loading="loading" @click="sendMessage">Send Message</a-button>
              </a-form-item>
            </a-form>
          </a-col>
        </a-row>
      </div>
    </div>
    <FooterComponent class="position-absolute w-100 bottom-0" />
  </div>
</template>

<script>
import NavbarComponent from "@/views/Navbar";
import FooterComponent from "@/views/Footer";
import {ref} from 'vue'
import {useMutation} from "@vue/apollo-composable";
import {SendContactUsMutationGql} from "@/gql/send-contactus-message-mutation-gql";
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n";
export default {
  name: "ContactusPage",
  components: {
    NavbarComponent,
    FooterComponent
  },
  setup() {
    const formState = ref({
      name: null,
      email: null,
      message: null
    });
    const {mutate, loading} = useMutation(SendContactUsMutationGql);
    const {t} = useI18n()
    const sendMessage = () => {
      mutate({
        name: formState.value.name,
        email: formState.value.email,
        message: formState.value.message
      }).then(() => {
        resetForm()
        message.success(t('messageSent'))
      });
    }

    const resetForm = () => {
      formState.value = {
        name: null,
        email: null,
        message: null
      };
    }

    return {
      formState,
      sendMessage,
      loading
    }
  }
}
</script>

<style scoped>

</style>