const locale = {
    // Nave Bar
    findWork: 'Find Work',
    feeds: "Home",
    search: "Search",
    profile: "Profile",
    setting: "Setting",
    logout: "Logout",
    notification: "Notifications",

    // Home Page
    enterJobTitle: "Enter Job Title",
    jobsMightLike: "Jobs you might like",
    bestMatches: "Best Matches",
    mostRecent: "Most Recent",
    noData: "Sorry, there is no data to show",
    subscriptionEndsAt: "Subscription Ends at",
    subscription: "Subscription",
    copyRights: "Doctors Gulf Corporation ©",
    postNewJob: "Post a New Job",
    viewProfile: "View Profile",
    advancedSearch: "Advanced Search",
    specialty: "Specialty",
    jobLocation: "Job Location",
    reset: "Reset",
    more: "More",

    //Home Job View
    posted: "Posted",
    viewApplications: "View Applications",
    edit: 'Edit',
    skillAndExperience: "Skills and Expertise",
    report: "Report",
    applyToJob: "Apply to job",
    aboutTheClient: "About the client",
    delete: "Delete",

    //Feeds
    whatIsInYourMind: "What is in your mind?",
    public: 'Public',
    private: 'Private',
    addPhoto: "Add Photo",
    addVideo: "Add Video",
    post: "Post",
    createPost: "Create a post",
    updatePost: "Update a post",
    like: "Like",
    liked: "Liked",
    comment: "Comment",
    share: "Share",
    shareLink: "Share Link",
    close: "Close",
    copy: "Copy",
    applied: "Applied",
    writeComment: "Write your comment",


    //Profile
    languages: "Languages",
    specialties: "Specialties",
    certificates: "Certificates",
    provider: "Provider",
    issued: "Issued",
    loadMore: "Load More",
    employmentHistory: "Employment history",
    title: "Title",
    startDate: "Start Date",
    endAtDate: "End Date",
    workedAt: "Worked At",
    deleteExperience: "Delete Experience",
    save: "Save",
    certName: "Certificate Name",
    issuedAt: "Issued At",
    certDoesntExpired: "This credential does not expire",
    expireAt: "Expire At",
    certId: "Certificate ID",
    pleaseSelectSpeciality: "Please select a specialty",
    certNoExpire: "This credential does not expire",

    optional: 'Optional',
    uploadResume: 'Upload resume',
    fieldOfStudy: "Field of study",
    school: "School",
    degree: "Degree",
    addEducation: "Add education",
    addEmploymentHistory: "Add Employment History",
    overview: "Overview",
    jobOverview: "Job Overview",
    speciality: "Speciality",
    specialities: "Specialities",
    rate: "Rate",
    review: "Review",

    //Lang
    basic: "Basic",
    conversational: "Conversational",
    proficient: "Proficient",
    fluent: "Fluent",
    addNewLanguage: "Add New Language",

    backHome: "Back to home",
    pageNotFound: "Sorry, the page you visited does not exist.",
    removeMedia: "Remove media",

    //Report
    whyReport: "Why are you reporting this?",
    fake: "Suspicious, spam, or fake",
    harmful: "Harassment or hateful speech",
    violence: "Violence or physical harm",
    sexual: "Sexual Content",

    pickupSubscription: "Pickup a plan to subscribe",
    applicationsOf: "Applications of",
    resume: "Resume",

    //Auth
    email: "Email",
    password: "Password",
    or: "Or",
    forgotPassword: "Forgot Password",
    backToLogin: "Back To Login Page",
    loginToAccount: "Login into your account",
    joinUs: "Join Us",
    signIn: "Sign In",
    resetPassword: "Reset Password",
    newPassword: "New Password",
    confirmPassword: "Confirmation Password",
    confirmNewPassword: "Confirmation New Password",
    resetTokenInvalid: "Reset password token is invalid!",
    thanksForReg: "Thank you for your registration.",
    currentlyReview: "Currently, We are reviewing your information, we will send you an email to inform you about the approval.",
    thanks: "Thanks",
    team: "Team",

    //Setting
    passAndSec: "Password & Security",
    changePassword: "Change Password",
    oldPassword: "Old Password",
    newSubscription: "New Subscription",
    subscriptionDetails: "Subscription Details",
    subRefId: "Subscription Reference ID",
    validTil: "Valid Till",
    subscriptionType: "Subscription Type",


    regNumber: "Registration Number",
    foundYear: "Foundation Year",
    address: "Address",
    location: "Location",
    website: "Website",
    phone: "Phone Number",
    reviews: "Reviews",
    loadLess: "Load Less",
    education: "Education",
    skills: "Skills",
    pricing: "Pricing",
    contactUs: "Contact Us",

    //Site
    weHlp: "We help you find the best job opportunity",
    weHlpParagraph: "DoctorsGulf is a one-stop platform for doctors and medical professionals to find the perfect career match. Whether you’re looking for a new position close to home or an adventure in an exotic locale",
    getStarted: "Get Started",
    weOffer: "We offer a complete",
    rangeFeature: "range of features",
    jobSearch: "Job Search and Filtering",
    jobSearchParagraph: "Allow doctors to search and filter job listings based on criteria such as specialization, location, contract type, experience level.",
    profileCreation: "Profile Creation",
    profileCreationParagraph: "Enable doctors to create comprehensive profiles highlighting their qualifications, experience, certifications, and areas of expertise.",
    ApplicationAndResumeSubmission: "Application and Resume Submission",

    ApplicationAndResumeSubmissionParagraph: "Allow doctors to easily apply for jobs by submitting their resumes and necessary documents online.",
    jobAlerts: "Job Alerts",
    jobAlertsParagraph: "Send notification alerts to doctors when new job opportunities matching their preferences and qualifications become available.",
    ratingAndReview: "Ratings and Reviews",
    ratingAndReviewParagraph: "Enable doctors to rate and review the employers and their experiences working with them, helping other doctors make more informed decisions.",
    proNetworking: "Professional Networking",
    proNetworkingParagraph: "Build features that allow doctors to connect and network with other professionals in their field, fostering collaboration and knowledge-sharing.",

    readyToGetStarted: "Ready to get started?",
    takeControl: "Take control of your Career. Try our service to make your career better",

    weOfferPrices: "We offer a variety of plans to meet your needs.",

    annual: "Annual",
    monthly: "Monthly",
    registerAsPro: "Register as a Practitioner",
    registerAsMed: "Register as a Medical Center",
    fullName: "Full Name",
    next: "Next",
    emailOrPassNotMatched: "Email or Password is not matched",
    emailRequired: 'Email is required',
    passRequired: 'Password is required',
    createAccount: 'Create Account',
    selectLocation: 'Please select your location',
    selectSpeciality: 'Please select your specialty',

    profileTitle: "Profile Title",

    myPostedJobs: 'My Posted Jobs',
    jobTitle: "Job Title",
    activity: 'Activity',

    editProfileImage: "Edit profile image",
    changeImage: "Choose a new Image",
    upload: 'Upload',

    profileUpdated: 'Profile has been updated successfully!',
    profileImage: 'Profile image has updated successfully!',
    commentAdded: 'Comment has been added successfully!',
    commentUpdated: 'Comment has been updated successfully!',
    requestApplied: 'Your request has been applied successfully!',
    certPosted: 'Certificate has been posted successfully!',
    eduUpdated: 'Education has been updated successfully!',
    eduDeleted: 'Education has been deleted successfully!',
    eduAdded: 'Education has been created successfully!',
    expAdded: 'Experience has been created successfully!',
    expUpdated: 'Experience has been updated successfully!',
    expDeleted: 'Experience has been deleted successfully!',
    jobUpdated: 'Job has been updated successfully!',
    jobAdded: 'Job has been created successfully!',
    langUpdated: 'Language has been updated successfully!',
    copied: 'Copied to clipboard!',
    reportAdded: 'Report has been sent successfully!',
    reviewAdded: "Review has been saved successfully!",
    messageSent: "Message has been sent successfully.",
    resetPassSent: "Reset password email has been sent successfully!",
    emailNotMatched: "Email is not matched!",
    passUpdated: "Password has been updated successfully!",
    oldPassNotMatched: "Old password was not matched.",
    success: 'Operation done successfully',
    emailIsNotValid: "Email is not valid",

    provideCertificate: 'Please add a verified certificate.',
    sendRequest: 'Send Request',
    languagesRequired: 'Languages inputs are required',
    noExpirationDate: 'No Expiration Date',
    areSreDeleteEx: 'Are you sure delete this experience?',
    deleteEducation: "Delete",
    yes: "Yes",
    no: 'No',
    editEmploymentHistory: "Edit Employment History",
    areSureDeletePost: 'Are you sure delete this post?',
    recoverDeletePost: 'Are you sure delete this post?',

    commentNotValid: 'Comment is not valid',
    commentMax: 'Comment must be up to 500 characters',
    update: "Update",
    reportReason: "Enter report reason",
    subscribeNow: 'Subscribe Now',
}

export default locale;
