<template>
<div class="container pt-5 pb-5">
  <a-row>
    <a-col :lg="{span: 20, offset: 2}">
      <a-row :gutter="[0, { xs: 8, sm: 16, md: 24, lg: 32 }]">
        <a-col :span="24">
          <a-card class="p-md-4 p-sm-2">
            <a-row :align="'middle'">
              <a-col :xs="{span: 0}" :sm="{span: 0}" :lg="{ span: 24 }">
                <a-space align="center">
                  <div class="profile-picture me-2 position-relative">
<!--                              <img :src="profile.profile_image" width="90" alt="user-avatar" class="rounded-circle" style="width: 80px;" v-if="profile.profile_image">-->
<!--                              <img src="@/assets/images/image.svg" width="90" alt="user-avatar" class="rounded-circle" style="width: 80px;" v-else>-->
                    <a-avatar :src="profile.image" :size="100" class="profile-avatar" />
                  </div>
                  <div class="ms-2">
                    <h5 class=" mt-2">
                      <a-space>
                      {{ profile.user.name }}
                      <a-button type="primary" shape="circle" ghost @click="router.push({name: 'edit-profile'})" v-if="editable">
                        <template #icon><EditOutlined /></template>
                      </a-button>
                      <a-button type="primary" shape="circle" ghost @click="editProfileImage" v-if="editable">
                        <template #icon><CameraOutlined /></template>
                      </a-button>
                      </a-space>
                    </h5>
                    <small>
                      {{ profile.location }}
                    </small>
                  </div>
                </a-space>
              </a-col>
              <a-col :xs="{span: 24}" :sm="{span: 24}" :lg="{ span: 0 }" class="text-center">
                <div class="profile-picture me-2 position-relative">
                  <!--          <img :src="profile.profile_image" width="90" alt="user-avatar" class="rounded-circle" style="width: 80px;" v-if="profile.profile_image">-->
                  <!--          <img src="@/assets/images/image.svg" width="90" alt="user-avatar" class="rounded-circle" style="width: 80px;" v-else>-->
                  <a-avatar :src="profile.image" :size="100" class="profile-avatar" />
<!--                  <div class="profile-avatar-edit position-absolute d-flex justify-content-center align-items-center" @click="editProfileImage">-->
<!--                    <EditOutlined class="text-white" />-->
<!--                  </div>-->
                </div>
                <a-space direction="vertical" class="w-75">
                  <div class="ms-2">
                    <h5 class="mt-2">
                      {{ profile.user.name }}
<!--                      <a-button type="primary" shape="circle" @click="router.push({name: 'edit-profile'})" size="small" v-if="editable">-->
<!--                        <template #icon><EditOutlined /></template>-->
<!--                      </a-button>-->
                    </h5>
                    <small>
                      {{ profile.location }}
                    </small>
                  </div>
                  <a-button class="mt-2" type="primary" block @click="editProfileImage">
                    <EditOutlined /> {{ $t('edit') }} {{$t('profile')}}
                  </a-button>
                </a-space>
              </a-col>
            </a-row>
            <a-row class="mt-4">
              <a-col :lg="{span: 8}" :sm="{span: 24 , order: 2}" :xs="{span: 24 , order: 2}" class="">
                <a-row>
                  <a-col :xs="{span: 24}" :sm="{span: 24}" :lg="{span: 23}">
                    <div class="container-fluid">
                      <!-- Languages Section -->
                      <div class="mt-3 mb-3">
                        <div class="fw-bolder mb-3">
                          {{ $t('languages') }}
                          <a-button type="link" shape="circle" @click="openLanguagesModal" v-if="editable">
                            <template #icon><EditOutlined /></template>
                          </a-button>
                        </div>
                        <div class="mb-2" v-for="(item, index) in profile.languages" :key="index">
                          <small class="fw-bold me-2">{{ item.title }}:</small>
                          <small>{{ item.value }}</small>
                        </div>
                      </div>
                      <!-- Education Section -->
                      <hr>
                      <div class="mt-4 mb-4">
                        <div class="fw-bolder mb-3">
                          {{ $t('education') }}
                          <a-button type="link" shape="circle" @click="openEducationModal()" v-if="editable">
                            <template #icon><PlusOutlined /></template>
                          </a-button>
                        </div>
                        <div class="mb-2" v-for="(item, index) in profile.education" :key="index">
                          <small class="fw-bold d-block">
                            {{ item.school }}:
                            <a-button type="link" shape="circle" @click="openEducationModal(item)" v-if="editable">
                              <template #icon><EditOutlined /></template>
                            </a-button>
                          </small>
                          <small>{{ moment(item.start_date).format('MMM YYYY') }} - {{ moment(item.end_date).format('MMM YYYY') }}</small>

                        </div>
                      </div>
                    </div>
                  </a-col>
                  <a-col :xs="{span: 0}" :sm="{span: 0}" :lg="{span: 1}">
                    <a-divider type="vertical" class="h-100 mt-3 mb-3" />
                  </a-col>
                </a-row>
              </a-col>
              <a-col :lg="{span: 16}" :sm="{span: 24 , order: 2}" :xs="{span: 24, order: 1}">
                <div class="container-fluid">
                  <div class="mt-3 mb-4">
                    <h5 class="fw-bold">{{ profile.title }}</h5>
                    <div class="bio mt-3">
                      <a-typography-paragraph
                          :ellipsis="ellipsis ? { rows: 4, expandable: true, symbol: 'more' } : false"
                          :content="profile.bio"
                      />
                    </div>
                  </div>
                  <hr>
                  <div class="mt-3 mb-4 border-bottom" v-if="profile.skills">
                    <h5 class="fw-bold">{{ $t('skills') }}:</h5>
                    <div class="bio mt-3">
                    <span class="badge text-bg-primary m-1" v-for="(skill, index) in profile.skills" :key="index">
                      {{ skill }}
                    </span>
                    </div>
                  </div>
                  <div class="mt-3 mb-4">
                    <h5 class="fw-bold">{{$t('specialties')}}:</h5>
                    <div class="bio mt-3">
                    <span class="badge text-bg-primary m-1" v-for="(item, index) in profile.specialties" :key="index">
                      {{ item.name }}
                    </span>
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-card>
        </a-col>

        <a-col :span="24">
          <a-card class="p-md-4 p-sm-2">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="fw-bold mt-2">
                {{ $t('certificates') }}
              </h5>
              <a href="#" class="btn btn-link" @click.prevent="openCertificateModal()">
                <img src="@/assets/icons/add.svg" width="28" alt="add-certificate">
              </a>
            </div>
            <div class="mt-4">
    <!--          <certificate_component :class="profile.certificates.length == (index+1) ? '' : 'border-bottom'" v-for="(item, index) in profile.certificates" :certificate="item" :key="index" />-->
              <!--            <hr>-->
              <CertificateComponent class="border-bottom" v-for="(item, index) of (certificatesLoadMore ? profile.certificates : profile.certificates.slice(0, 3))" :certificate="item" :editable="editable" :key="index" />
            </div>
            <div class="mt-4 text-center" v-if="profile.certificates.length > 3">
              <a-button type="primary" @click.prevent="certificatesLoadMore = false" v-if="certificatesLoadMore">
                {{ $t('loadLess') }}
              </a-button>
              <a-button type="primary" @click.prevent="certificatesLoadMore = true" v-else>
                {{ $t('loadMore') }}
              </a-button>
            </div>
          </a-card>
        </a-col>

        <a-col :span="24">
          <a-card class="p-md-4 p-sm-2">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="fw-bold mt-2">
                {{ $t('employmentHistory') }}
              </h5>
              <a href="#" class="btn btn-link" @click.prevent="openEmploymentHistoryModal()" v-if="editable">
                <img src="@/assets/icons/add.svg" width="28" alt="add-certificate">
              </a>
            </div>
            <div class="mt-3" v-if="profile.employment_history.length > 0">
              <EmploymentHistoryComponent class="border-bottom"
                v-for="(item, index) in (employmentHistoryLoadMore ? profile.employment_history : profile.employment_history.slice(0, 3))"
                :employment_history="item" :editable="editable"
                :key="index"
                @click="openEmploymentHistoryModal(item)"
              />
              <div class="text-center mt-4" v-if="profile.employment_history.length > 3">
                <a-button type="primary" @click.prevent="employmentHistoryLoadMore = false" v-if="employmentHistoryLoadMore">
                  {{ $t('loadLess') }}
                </a-button>
                <a-button type="primary" @click.prevent="employmentHistoryLoadMore = true" v-else>
                  {{ $t('loadMore') }}
                </a-button>
              </div>
            </div>
            <div class="mt-3 text-center text-muted" v-else>
              {{ $t('noData') }}
            </div>
          </a-card>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
  <LanguagesModalComponent />
  <EducationModalComponent />
  <EmploymentHistoryModalComponent />
</div>
</template>

<script>
import CertificateComponent from "@/components/CertificateComponent";
import EmploymentHistoryComponent from "@/components/EmploymentHistoryComponent";
import {CameraOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {inject, onMounted, ref} from 'vue'
import {useRouter} from "vue-router/dist/vue-router";
import LanguagesModalComponent from "@/components/LanguagesModalComponent";
import EducationModalComponent from "@/components/EducationModalComponent";
import EmploymentHistoryModalComponent from "@/components/EmploymentHistoryModalComponent";
import {useStore} from "vuex";
export default {
  name: "ProfessionalProfileView",
  components: {
    CameraOutlined,
    EmploymentHistoryModalComponent,
    EducationModalComponent,
    PlusOutlined,
    LanguagesModalComponent, CertificateComponent, EmploymentHistoryComponent, EditOutlined},
  props: {
    profile: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const store = useStore()
    const editable = ref(false);
    const moment = inject('moment')
    const emitter = inject('emitter')
    const router = useRouter()
    const ellipsis = ref(true);
    const certificatesLoadMore = ref(false);
    const employmentHistoryLoadMore = ref(false);
    const openCertificateModal = () => {
      emitter.emit('openCertificateModal')
    }
    const updateCertificateLoadMore = (value) => {
      certificatesLoadMore.value = value
    }
    const openLanguagesModal = () => {
      emitter.emit('openLanguagesModal', {
        profile_id: props.profile.id,
        languages: props.profile.languages
      })
    }
    const openEducationModal = (data) => {
      emitter.emit('openEducationModal', {
        profile_id: props.profile.id,
        education: data
      })
    }
    const openEmploymentHistoryModal = (data) => {
      emitter.emit('openEmploymentHistoryModal', {
        profile_id: props.profile.id,
        employment_history: data ? JSON.parse(JSON.stringify(data)) : null
      });
    }
    onMounted(() => {
      if (store.state.authedUser.user.profile.profile_slug == props.profile.profile_slug) {
        editable.value = true;
      }
    })
    const editProfileImage = () => {
      emitter.emit('editProfileImage', {
        profile_id: props.profile.id,
        image: props.profile.image
      });
    }
    return {
      ellipsis,
      router,
      openCertificateModal,
      certificatesLoadMore,
      updateCertificateLoadMore,
      employmentHistoryLoadMore,
      openLanguagesModal,
      openEducationModal,
      openEmploymentHistoryModal,
      moment,
      editable,
      editProfileImage
    }
  }
  // data() {
  //   return {
  //     profile: {
  //       bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //       profile_image: '',
  //       title: 'Senior Backend Developer',
  //       location: 'Cairo, Egypt',
  //       user: {
  //         name: 'Hisham Magdy'
  //       },
  //       languages: [
  //         {
  //           title: "English",
  //           value: "Very Good"
  //         },
  //         {
  //           title: "Arabic",
  //           value: "Very Good"
  //         },
  //       ],
  //       skills: [
  //           "Hard worker",
  //           "test test test"
  //       ],
  //       education: [
  //         {
  //           school: "Ain Shams Uni."
  //         }
  //       ]
  //     }
  //   }
  // }
}
</script>

<style scoped>
/*.profile-avatar:hover {*/
/*  display: none;*/
/*}*/


</style>
