<template>
  <div>
    <navbar-component/>
    <!--    Home page-->
<!--    <div class="section bg-light pt-5 pb-5">-->
<!--      <div class="container">-->
<!--        <div class="row bg-light">-->
<!--          <div class="col-10 mt-auto mb-auto">-->
<!--            <h2>{{ $t('homepage.bestSolution') }}</h2>-->
<!--            <p>-->
<!--              Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no suscipit quaerendum. At nam minimum ponderum. Est audiam animal molestiae te. Ex duo eripuit mentitum.-->
<!--            </p>-->
<!--            <button class="btn btn-primary">Start free trial</button>-->
<!--          </div>-->
<!--          <div class="col-14">-->
<!--            <img class="w-100" src="@/assets/images/undraw_doctors.svg" alt="doctor-in">-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="container mt-5 pt-5 pb-5">
      <div class="text-center">
        <h2>
          {{$t('weOfferPrices')}}
        </h2>
      </div>
      <div class="d-flex mt-5 justify-content-center" v-if="loading">
        <loading-outlined style="font-size: 50px;" />
      </div>
      <div class="row mt-1 g-5 justify-content-center" v-else>
        <div class="col-6 border rounded-3 me-4 pt-5 pb-5 text-center">
          <h3>Professionals</h3>
          <p>
            AED {{ parseFloat(proSubscriptions[0].subscription_amount).toFixed(2) }}/month*
            <br>  
            AED {{ parseFloat(proSubscriptions[1].subscription_amount).toFixed(2) }}/year*
          </p>
          <button class="btn btn-primary" @click="router.push('signup')">
            Subscribe
          </button>
        </div>
        <div class="col-6 border rounded-3 me-4 pt-5 pb-5 text-center">
          <h3>Medical Centers</h3>
          <p>
            AED {{ parseFloat(medicalSubscriptions[0].subscription_amount).toFixed(2) }}/month*
            <br>
            AED {{ parseFloat(medicalSubscriptions[1].subscription_amount).toFixed(2) }}/year*
          </p>
          <button class="btn btn-primary" @click="router.push('signup')">
            Subscribe
          </button>
        </div>
<!--        <div class="col-8" align="center" v-for="(item, index) of features" :key="index">-->
<!--          <div class="row g-2">-->
<!--            <div class="col-24">-->
<!--              <img :src="item.image.url" :width="item.image.width" :height="item.image.height">-->
<!--            </div>-->
<!--            <div class="col-24">-->
<!--              <h4>-->
<!--                {{ item.title }}-->
<!--              </h4>-->
<!--            </div>-->
<!--            <div class="col-24">-->
<!--              <small>-->
<!--                {{item.content}}-->
<!--              </small>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

    <div class="bg-light pt-5 pb-5 mt-5">
      <div class="container">
        <div class="row g-3 mt-5 mb-5">
          <div class="col-24" align="center">
            <h2>Ready to get started?</h2>
          </div>
          <div class="col-24" align="center">
            <p>
              Take control of your Business Data. <br>
              Try saas to make your business better
            </p>
          </div>
          <div class="col-24" align="center">
            <button class="btn btn-primary" @click="router.push('signup')">Get Started</button>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script setup>
import NavbarComponent from "@/views/Navbar";
import FooterComponent from "@/views/Footer";
import {useQuery} from "@vue/apollo-composable";
import GetSubscriptionsGql from "@/gql/get-subscriptions-gql";
import {ref} from "vue";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";

const router = useRouter()
const proSubscriptions = ref([]);
const medicalSubscriptions = ref([]);

const {result, onResult} = useQuery(GetSubscriptionsGql, {}, {
  clientId: 'public'
})

onResult(() => {
  proSubscriptions.value = result.value.subscriptions.filter(x => x.subscription_user_type == 'user');
  medicalSubscriptions.value = result.value.subscriptions.filter(x => x.subscription_user_type == 'medical_center')
})
</script>
